import React from 'react'
import './footer.style.css'
import { CApp } from '../../constants/CApp'

const Footer = () => {
    return (
        <>
            <div className="footer" >
                <span>Copyright © DFS Services LLC</span>
                <span>Version {CApp.Vesion}</span>
            </div>
        </>
    )
}

export default Footer